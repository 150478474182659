import { Navbar, VoteCards, Footer } from "../Components";

const VotePage = () => {
  return (
    <div>
      <Navbar />
      <VoteCards />
      <Footer />
    </div>
  );
};

export default VotePage;
