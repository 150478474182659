import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
import "./CSS/FAQ.css";

const FAQ = () => {
  const faqContainerRef = useRef(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
    setInitialized(true);

    const faqContainer = faqContainerRef.current;

    if (!faqContainer) {
      return; // exit early if the container is not found
    }

    const questions = faqContainer.querySelectorAll(".question");

    questions.forEach((question) => {
      question.addEventListener("click", () => {
        const isActive = question.classList.contains("active");
        const answer = question.nextElementSibling;

        questions.forEach((q) => {
          q.classList.remove("active");
          if (q.nextElementSibling) {
            q.nextElementSibling.style.maxHeight = "0";
          }
          const icon = q.querySelector("i");
          if (icon) {
            icon.style.transform = "rotate(0deg)";
          }
        });

        if (!isActive) {
          question.classList.add("active");
          if (answer) {
            answer.style.maxHeight = answer.scrollHeight + "px";
          }
          const icon = question.querySelector("i");
          if (icon) {
            icon.style.transform = "rotate(180deg)";
          }
        }
      });
    });
  }, []);

  return (
    <section id="faq">
      <div className="container">
        <h2 data-aos={initialized ? "fade-up" : ""}>Frequently Asked Questions</h2>
        <div className="faq-container" ref={faqContainerRef} data-aos={initialized ? "fade-up" : ""}>
          <ul className="faq-list">
            <FAQItem question="What is Cyberblox?" answer="<strong>Cyberblox</strong> is a <strong>Roblox-Discord Bot</strong> with much more than the standard verification, featuring lots of commands to help your group for free. Most features provided by <strong>Cyberblox</strong> have never been seen on a <strong>Roblox-Discord Bot</strong> before!" />
            <FAQItem question="What Is Cyberblox Premium?" answer="<strong>Cyberblox Premium</strong> is a <strong>subscription-biased service</strong> paid yearly or monthly to gain access to <strong>Cyberblox's premium features.</strong>" />

            <FAQItem question="Why should I use Cyberblox?" answer="Using <strong>Cyberblox</strong> has many <strong>advantages</strong>, providing tons of <strong>features</strong> for you to continue to <strong>grow and expand</strong> your Roblox group." />
            <FAQItem question="How do I get in contact with Cyberblox support?" answer="To get in contact with <strong>Cyberblox support</strong>, please visit <strong>cyberblox.org/support</strong>" />
          </ul>
        </div>
      </div>
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  return (
    <li>
      <div className="question">
        <h3>{question}</h3>
        <FontAwesomeIcon icon={faChevronDown} className="question-icon" />
      </div>
      <div className="answer">
        {/* Use dangerouslySetInnerHTML to render HTML content */}
        <p dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </li>
  );
};

export default FAQ;
