import { Navbar, DiscordMessageEditor, Footer } from "../Components";

const DiscordToolsPage = () => {
  return (
    <div>
      <Navbar />
      <DiscordMessageEditor />
      <Footer />
    </div>
  );
};

export default DiscordToolsPage;
