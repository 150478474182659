import React from "react";
import "./CSS/Legal.css";

const PrivacyPolicy = () => {
  return (
    <section id="legal">
      <div className="container-legal legal">
        <h1 className="legal">Privacy Policy</h1>

        <p className="legal">Last Updated: [1/1/2024]</p>

        <p className="legal">This Privacy Policy describes how Cyberblox collects, uses, discloses, and protects the personal information of its users when they use Cyberblox. By using Cyberblox, you consent to the terms of this Privacy Policy.</p>

        <h2 className="legal">Information We Collect</h2>

        <p className="legal">We may collect personal information and public information that you provide to us voluntarily when you use Cyberblox. The types of information we may collect include, but are not limited to:</p>
        <ul className="legal">
          <li>Your name and email</li>
          <li>Essential discord account information to allow access to our dashboard</li>
          <li>Roblox account Username, User ID, Group ID, Group Role ID's</li>
          <li>Server ID's, Server channel ID's, Discord account ID's</li>
          <li>
            <b>We do not collect credit card information; all billing is managed through Stripe&reg;. Please see their Privacy Policy about the information they collect.</b>;
          </li>
        </ul>

        <h2 className="legal">How We Use Your Information</h2>

        <p className="legal">We may use the information we collect for various purposes. We will only use your personal information for the purposes disclosed in this Privacy Policy unless you provide your explicit consent for other uses.</p>

        <h2 className="legal">How We Disclose Your Information</h2>

        <p className="legal">We may disclose your personal information to third parties in certain circumstances, including when required by law, or to our service providers. We will only disclose your personal information for the purposes disclosed in this Privacy Policy or with your explicit consent. Some Examples are:</p>
        <ul className="legal">
          <li>When required by law</li>
          <li>
            When required by certain service providers<b>*</b>
          </li>
        </ul>

        <h2 className="legal">Data Security</h2>

        <p className="legal">We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
        <ul className="legal">
          <li>
            <b>All data is only accessible by certain members of the Cyberblox team.</b>
          </li>
        </ul>

        <h2 className="legal">Third-Party Links</h2>

        <p className="legal">Cyberblox may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such third-party websites or services. We encourage you to read the privacy policies of those third parties to understand how they collect, use, and disclose your information.</p>

        <h2 className="legal">Children's Privacy</h2>

        <p className="legal">Cyberblox is not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to delete your information from our systems.</p>

        <h2 className="legal">Changes to this Privacy Policy</h2>

        <p className="legal">We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with a revised "Last Updated" date. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.</p>

        <br />
        <br />

        <h1 className="legal">Contact Us</h1>

        <p className="legal">
          If you have any questions or concerns regarding these Terms, please contact us at{" "}
          <a href="/support" title="Contact Support">
            cyberblox.org/support
          </a>
          .
        </p>

        <br />

        <p className="legal">
          <b>*No current providers.</b>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
