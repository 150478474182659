import { Navbar, Attributions, Footer } from "../Components";

const AttributionsPage = () => {
  return (
    <div>
      <Navbar />
      <Attributions />
      <Footer />
    </div>
  );
};

export default AttributionsPage;
