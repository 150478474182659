import { Navbar, Hero, Features, Statistics, FAQ, CTA, Footer } from "../Components";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Features />
      <Statistics />
      <FAQ />
      <CTA />
      <Footer />
    </div>
  );
};

export default HomePage;
