// SupportCard.jsx
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CSS/SupportCard.css";

const SupportCard = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="page-container">
      <div className="contact-information" data-aos="fade-up">
        <section className="contact-section">
          <div className="contact-content">
            <h1 className="support-heading" data-aos="fade-up">
              Welcome to Cyberblox Support!
            </h1>
            <p className="support-description" data-aos="fade-up">
              If you have any questions or need any assistance, please feel free to reach out to us. We're always happy to help!
            </p>
            <div className="contact-info">
              <p className="contact-email" data-aos="fade-up">
                <span className="email-prefix">Contact us via email at</span>{" "}
                <a href="mailto:support@cyberblox.org" title="Email Support!" className="email-link">
                  support@cyberblox.org
                </a>
              </p>
              <p className="discord-join-description" data-aos="fade-up">
                Join our Discord server for community support:
              </p>
              <a href="https://discord.gg/QTbZRZVgBt" title="Join our Discord!" className="discord-link" data-aos="fade-up">
                Join Discord
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SupportCard;
