import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import "./CSS/CTA.css";

const CTASection = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
    setInitialized(true);
  }, []);

  return (
    <section id="get-started">
      <div className="container">
        <h2 data-aos={initialized ? "fade-up" : ""}>Get Started</h2>
        <p className="cta-paragraph" data-aos={initialized ? "fade-up" : ""}>
          With the simple click of a button invite Cyberblox today!
        </p>
        <a href="https://invite.cyberblox.org/" title="Invite Cyberblox" target="_blank" rel="noopener noreferrer" className="btn btn-primary" data-aos={initialized ? "fade-up" : ""}>
          Invite Cyberblox
        </a>
      </div>
    </section>
  );
};

export default CTASection;
