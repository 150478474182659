import React from "react";
import "./CSS/Legal.css";

const Atributions = () => {
  return (
    <section id="legal">
      <div className="container-legal legal">
        <h1 className="legal">Cyberblox Atributions</h1>

        <p className="legal">Last Updated: [1/1/2024]</p>

        <p className="legal">Welcome to Cyberblox! This page is dedicated to recognizing and expressing gratitude to the individuals, organizations, and resources that have contributed to the development and success of Cyberblox.</p>

        <h2 className="legal">Contributors and Team Members</h2>
        <p className="legal">Our heartfelt thanks to the team members and contributors who have played a crucial role in shaping Cyberblox:</p>
        <ul className="legal">
          <li>
            <b>@Michael_Master13</b> - [Full Stack Developer & Cyberblox Management]
          </li>
          <li>
            <b>@George_Master13</b>- [Full Stack Developer, Graphics & Front-End Management]
          </li>

          {/* <li>
            <b>[Contributor 1]</b>- [Contribution Details] - [LinkedIn Profile
            or Personal Website, if applicable]
          </li> */}
        </ul>
        <br />
        <h2 className="legal">Support Team</h2>
        <ul className="legal">
          <li>
            <b>@noah.cb</b>- [Support Team]
          </li>
        </ul>
        <br />
        <h2 className="legal">Beta Testers</h2>
        <ul className="legal">
          <li>
            <b>@bbgaming25k</b>- [Beta Tester]
          </li>
          <li>
            <b>@milo0928</b>- [Beta Tester]
          </li>
          <li>
            <b>@fqrs.</b>- [Beta Tester]
          </li>
        </ul>
        <br />
        <h2 className="legal">Open Source Projects, Libraries and Assets</h2>
        <p className="legal">Cyberblox extends its gratitude to the developers and maintainers of the following open source projects, libraries and Assets:</p>
        <ul className="legal">
          <li>
            <b>Search Icon</b> - by Chanut - Flaticon
          </li>
          <li>
            <b>Departments Icon</b> - by Wichai.wi - Flaticon
          </li>
          <li>
            <b>Right Icon</b> - by Pixel perfect - Flaticon
          </li>
          <li>
            <b>User Icon</b> - by Tanah Basah - Flaticon
          </li>
          <li>
            <b>User Icon</b> - by Creatype - Flaticon
          </li>
          <li>
            <b>Schedule Icon</b> - by Freepik - Flaticon
          </li>
          <li>
            <b>Next Icon</b> - by Roundicons - Flaticon
          </li>
          <li>
            <b>Pending Icon</b> - by Wira Stocker - Flaticon
          </li>
          <li>
            <b>Settings Icon</b> - by Freepik - Flaticon
          </li>
          <li>
            <b>List Icon</b> - by Freepik - Flaticon
          </li>
          <li>
            <b>Help Icon</b> - by Ilham Fitrotul Hayat - Flaticon
          </li>
          <li>
            <b>Repair Icon</b> - by Freepik - Flaticon
          </li>
          <li>
            <b>Notification Icon</b> - by ghufronagustian - Flaticon
          </li>
          <li>
            <b>Star Icons</b> - by Dave Gandy - Flaticon
          </li>
          <li>
            <b>Line Icon</b> - by Fathema Khanom - Flaticon
          </li>
          <li>
            <b>Statistics Icon</b> - Emoji.gg
          </li>
          <br />
          <li>
            <b>FontAwesome.com</b> - Website Icons
          </li>
        </ul>

        <h2 className="legal">Special Thanks</h2>
        <p className="legal">Special thanks to:</p>
        <ul className="legal">
          <li>
            <b>@ghostcozy</b> - Provided Programing support and assisted with backend security.
          </li>
          <li>
            <b>@Dr_SugarBooga</b> - Provided VPS set up support.
          </li>
        </ul>

        <h2 className="legal">Disclaimer</h2>
        <p className="legal">Cyberblox may incorporate third-party technologies, trademarks, or resources. All such materials are the property of their respective owners. Any references to third-party content are for informational purposes only and do not imply endorsement or affiliation.</p>

        <h2 className="legal">Acknowledgments and Attributions</h2>
        <p className="legal">We would like to express our gratitude to all individuals, organizations, and resources that have contributed to the development and success of Cyberblox. Their support and expertise have played a crucial role in shaping our journey.</p>

        <p className="legal">If we have inadvertently omitted any individual or organization deserving of recognition, we sincerely apologize and invite you to contact us to rectify the oversight.</p>

        <p className="legal">Thank you for being part of the Cyberblox journey!</p>

        <br />
        <br />

        <h1 className="legal">Contact Us</h1>

        <p className="legal">
          If you have any questions or concerns regarding these Attributions, please contact us at{" "}
          <a href="/support" title="Contact Support">
            cyberblox.org/support
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default Atributions;
