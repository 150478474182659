import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faYoutube, faDiscord } from "@fortawesome/free-brands-svg-icons";

import "./CSS/Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <footer>
      <div class="footer-links">
        <nav>
          <a href="/" onClick={(event) => handleNavigation(event, "/")} title="Home">
            Home
          </a>
          <a href="https://docs.cyberblox.org/" title="Docs" target="_blank" rel="noreferrer">
            Docs
          </a>
          <a href="/support" onClick={(event) => handleNavigation(event, "/support")} title="Contact Support">
            Support
          </a>
          {/* <a href="#">Portfolio</a> */}
        </nav>
      </div>
      <div class="legal-links">
        <nav>
          <a href="/privacy-policy" onClick={(event) => handleNavigation(event, "/privacy-policy")} title="Privacy Policy">
            Privacy Policy
          </a>
          <a href="/terms-of-service" onClick={(event) => handleNavigation(event, "/terms-of-service")} title="Terms of Service">
            Terms of Service
          </a>
          <a href="/cookie-policy" onClick={(event) => handleNavigation(event, "/cookie-policy")} title="Cookie Policy">
            Cookie Policy
          </a>
          <a href="/attributions" onClick={(event) => handleNavigation(event, "/attributions")} title="Attributions">
            Attributions
          </a>
        </nav>
      </div>
      <div class="social-icons">
        <a href="https://discord.cyberblox.org/" title="Join our Discord!" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faDiscord} />
        </a>
        <a href="https://twitter.com/CyberbloxHQ/" title="Follow us on Twitter!" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://www.youtube.com/@Cyberblox-Services" title="Subscribe to us on YouTube!" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a href="mailto:support@cyberblox.com" title="Email Support">
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
      <div class="copyright">
        <p>&copy; 2024 Cyberblox. All Rights Reserved.</p>
        <div className="badges">
          <div class="dmca-badge">
            <a href="https://www.dmca.com/r/6qe9zlg" title="DMCA.com Protection Status">
              <img src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=d2713895-5a4f-4c0e-8824-66cc9e233a60" alt="DMCA.com Protection Status" />
            </a>
          </div>
          <div class="betterstack">
            <a target="_blank" rel="noopener noreferrer" href="https://uptime.betterstack.com" title="betterstack.com">
              <img style={{ width: "130px", height: "52px" }} alt="Better Stack Website Monitoring" src="https://uptime.betterstack.com/assets/static_assets/badges/light.png" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
