import React from "react";
import "./CSS/Hero.css";
import { heroImage } from "../Assets";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="hero-text">
          <h1 className="hero-heading">
            <span className="blue-text">Cyberblox</span> the{" "}
            <span className="red-text">
              <br />
              Roblox-Discord
            </span>{" "}
            app.
          </h1>
          <p className="hero-paragraph">Do everything you need all from one discord app. Roblox Verification & Connections, Moderation, Utilities, Staff Management and so much more. With our simple set up it is easy to invite Cyberblox Today!</p>
          <a className="get-started-button" href="https://invite.cyberblox.org/" title="Invite Cyberblox" target="_blank" rel="noreferrer">
            Invite Cyberblox
          </a>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="Showcasing Cyberblox's features." />
        </div>
      </div>
    </div>
  );
};

export default Hero;
