import { Navbar, SupportCard, Footer } from "../Components";

const SupportPage = () => {
  return (
    <div>
      <Navbar />
      <SupportCard />
      <Footer />
    </div>
  );
};

export default SupportPage;
