import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion, faStar, faShoppingCart, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "./CSS/PremiumTable.css";
import "aos/dist/aos.css";
import AOS from "aos";

const PremiumTable = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Set the duration of animations
      once: true, // Set whether animation should happen only once
    });
  }, []);

  return (
    <section className="premium-table" id="pricing">
      <h1 className="premium-heading">Cyberblox Premium</h1>
      <div className="pricing-cards">
        <div className="premium-card" data-aos="fade-up">
          <h3>Basic</h3>
          <p className="card-description">Cyberblox Basic, Basic perks for everybody free. Popular for new users.</p>
          <ul className="features">
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Verify Members
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Basic Commands
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Unlimted Servers
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Basic Customizability
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Verify Your Whole Server (2x/M)
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faQuestion} />
              </i>
              24/7 Support
            </li>
          </ul>
          <p className="price">Free, Forever</p>
          <button className="subscribe-button" onClick={() => window.open("https://discord.com/api/oauth2/authorize?client_id=1100883987578228746&permissions=18855946480887&scope=applications.commands+bot", "_blank")}>
            <FontAwesomeIcon icon={faUserPlus} /> Invite Cyberblox
          </button>
        </div>
        <div className="premium-card popular" data-aos="fade-up" data-aos-delay="100">
          <div className="popular-circle">
            <FontAwesomeIcon icon={faStar} />
            Most Popular
          </div>
          <h3>Premium Monthly</h3>
          <p className="card-description">Cyberblox Premium Monthly, same perks as yearly just pay by the month. Popular for upcoming users.</p>
          <ul className="features">
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Everything in Basic plus:
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Premium Commands
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Verify Your Whole Server
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Unlimited Premium Servers
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              View all perks at:
            </li>
            <li>
              <a href="/premium-perks">cyberblox.org/premium-perks</a>
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faQuestion} />
              </i>
              24/7 Support
            </li>
          </ul>
          <p className="price">$4.99/month</p>
          <button className="subscribe-button" onClick={() => (window.location.href = "/api/create-monthly-checkout-session")}>
            <FontAwesomeIcon icon={faShoppingCart} /> Subscribe
          </button>
        </div>
        <div className="premium-card" data-aos="fade-up" data-aos-delay="200">
          <h3>Premium Yearly</h3>
          <p className="card-description">Cyberblox Premium Yearly, same perks as monthly just pay by the year. Popular for long term users.</p>
          <ul className="features">
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Everything in Basic plus:
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Premium Commands
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Verify Your Whole Server
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              Unlimited Premium Servers
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faCheck} />
              </i>
              View all perks at:
            </li>
            <li>
              <a href="/premium-perks">cyberblox.org/premium-perks</a>
            </li>
            <li>
              <i>
                <FontAwesomeIcon icon={faQuestion} />
              </i>
              24/7 Support
            </li>
          </ul>
          <p className="price">$45.99/year</p>
          <button className="subscribe-button" onClick={() => (window.location.href = "/api/create-yearly-checkout-session")}>
            <FontAwesomeIcon icon={faShoppingCart} /> Subscribe
          </button>
        </div>
      </div>
    </section>
  );
};

export default PremiumTable;
