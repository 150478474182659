import React, { useState } from "react";
import "./CSS/DiscordMessageEditor.css";

const DiscordMessageEditor = () => {
  const [message, setMessage] = useState("");
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleBoldClick = () => {
    const selectedText = message.substring(selectionStart, selectionEnd);
    const boldText = `***${selectedText}***`;
    const newMessage = message.substring(0, selectionStart) + boldText + message.substring(selectionEnd);
    setMessage(newMessage);
  };

  const handleUnderlineClick = () => {
    const selectedText = message.substring(selectionStart, selectionEnd);
    const underlineText = `___${selectedText}___`;
    const newMessage = message.substring(0, selectionStart) + underlineText + message.substring(selectionEnd);
    setMessage(newMessage);
  };

  const handleSelectionChange = (event) => {
    setSelectionStart(event.target.selectionStart);
    setSelectionEnd(event.target.selectionEnd);
  };

  const parseMessage = (message) => {
    let parsedMessage = message.replace(/\*\*\*(.*?)\*\*\*/g, "<strong>$1</strong>"); // Bold
    parsedMessage = parsedMessage.replace(/___(.*?)___/g, "<u>$1</u>"); // Underline
    return parsedMessage;
  };

  return (
    <div className="discordMessageEditor">
      <div className="toolbar">
        <button onClick={handleBoldClick}>Bold</button>
        <button onClick={handleUnderlineClick}>Underline</button>
        {/* Add more formatting buttons here */}
      </div>
      <div className="content">
        <textarea className="message-input" placeholder="Type your message here..." value={message} onChange={handleMessageChange} onSelect={handleSelectionChange} />
        <div className="preview">
          <h2>Preview:</h2>
          <div className="message-preview" dangerouslySetInnerHTML={{ __html: parseMessage(message) }} />
        </div>
      </div>
    </div>
  );
};

export default DiscordMessageEditor;
