import { Navbar, TOS, Footer } from "../Components";

const TOSPage = () => {
  return (
    <div>
      <Navbar />
      <TOS />
      <Footer />
    </div>
  );
};

export default TOSPage;
