import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLink, faLock } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
import "./CSS/Features.css";

const FeaturesSection = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
    setInitialized(true);
  }, []);

  return (
    <section id="features">
      <div className="container">
        <h2 data-aos={initialized ? "fade-up" : ""}>Our Features</h2>
        <p className="feature-paragraph" data-aos={initialized ? "fade-up" : ""}>
          No need to keep searching for other discord bot's Cyberblox does it all. Roblox - Discord Verification, Discord Moderation, Utilities, Staff Management and so much more!
        </p>
        <div className="features-grid">
          <Feature icon={<FontAwesomeIcon icon={faCog} />} title="Easily Configurable!" description="Cyberblox's dashboard is one of the easiest dashboards to use with clean and concise configuration tabs, as well as lots of customizable abilities." />
          <Feature icon={<FontAwesomeIcon icon={faLink} />} title="Roblox-Discord Link!" description="Cyberblox's top feature is Roblox to discord linkage! Cyberblox provides much more than the standard verifying feature. It has tons of Roblox features brought over to Discord for simple and easy use!" />
          <Feature icon={<FontAwesomeIcon icon={faLock} />} title="Security" description="Cyberblox's database is managed by one individual, keeping everything encrypted and secure. We are also very transparent in our Privacy Policy about the data we collect!" />
        </div>
      </div>
    </section>
  );
};

const Feature = ({ icon, title, description }) => {
  return (
    <div className="feature" data-aos="fade-up">
      <div className="feature-icon">{icon}</div>
      <h3>{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  );
};

export default FeaturesSection;
