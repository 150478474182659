import { Navbar, VerificationCompleteCard, Footer } from "../Components";

const VerificationCompletePage = () => {
  return (
    <div>
      <Navbar />
      <VerificationCompleteCard />
      <Footer />
    </div>
  );
};

export default VerificationCompletePage;
