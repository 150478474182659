import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faGraduationCap, faProjectDiagram, faCrown, faHammer, faBell, faUser, faShieldHalved, faVial, faBug, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { BWLogo } from "../Assets";
import "./CSS/UserProfile.css";

function UserProfile() {
  let { id } = useParams();
  const [profileData, setProfileData] = useState({});
  const [userData, setUserData] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    const getProfileData = async () => {
      if (id) {
        try {
          const response = await fetch(`/api/fetch-profile-data/${id}`);
          if (response.ok) {
            const data = await response.json();
            setProfileData(data.message);
            setUserData(data.userData);
          } else {
            console.error("Failed to fetch data");
            window.location.href = "/404";
          }
          const fetchUserResponse = await fetch(`/api/fetch-user/${id}`);
          if (response.ok) {
            const data = await fetchUserResponse.json();
            setUser(data);
          } else {
            console.error("Failed to fetch data");
            window.location.href = "/404";
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          window.location.href = "/404";
        } finally {
        }
      }
    };

    getProfileData();
  }, [id]);

  return (
    <div className="profile-container">
      <Helmet>
        <title>{user.username ? `${user.username}'s Profile` : "User Profile"}</title>
        <meta name="description" content={profileData.bio ? profileData.bio : "User profile page"} />
        <meta property="og:title" content={user.username ? `${user.username}'s Profile` : "User Profile"} />
        <meta property="og:description" content={profileData.bio ? profileData.bio : "User profile page"} />
        <meta property="og:image" content={user.userAvatar ? user.userAvatar : BWLogo} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="profile-header">
        <img src={user.userAvatar} alt="Profile" className="profile-picture" />
        <div className="user-info">
          <h1 className="user-name">{user.username}</h1>
          <div className="badge-container">
            {profileData.badges && profileData.badges.owner && (
              <div className="badge badge-founder" title="Founder">
                <FontAwesomeIcon icon={faCrown} />
              </div>
            )}
            {profileData.badges && profileData.badges.developer && (
              <div className="badge badge-developer" title="Developer">
                <FontAwesomeIcon icon={faHammer} />
              </div>
            )}
            {profileData.badges && profileData.badges.ert && (
              <div className="badge badge-emergency" title="Emergency Response Team">
                <FontAwesomeIcon icon={faBell} />
              </div>
            )}
            {profileData.badges && profileData.badges.st && (
              <div className="badge badge-staff" title="Staff Team">
                <FontAwesomeIcon icon={faUser} />
              </div>
            )}
            {profileData.badges && profileData.badges.moderator && (
              <div className="badge badge-moderator" title="Moderator">
                <FontAwesomeIcon icon={faShieldHalved} />
              </div>
            )}
            {profileData.badges && profileData.badges.tester && (
              <div className="badge badge-tester" title="Tester">
                <FontAwesomeIcon icon={faVial} />
              </div>
            )}
            {profileData.badges && profileData.badges.bh && (
              <div className="badge badge-bug-hunter" title="Bug Hunter">
                <FontAwesomeIcon icon={faBug} />
              </div>
            )}
            {userData.verified && userData.verified && (
              <div className="badge badge-verified" title="Verified">
                <FontAwesomeIcon icon={faCircleCheck} />
              </div>
            )}
          </div>
          <p className="user-title">{profileData.job && profileData.job.title}</p>
          <p className="user-location">{profileData.job && profileData.job.subTitle ? profileData.job.subTitle : ""}</p>
        </div>
      </div>
      <div className="profile-details">
        <div className="bio-section">
          <h2 className="bio-title">Bio</h2>
          <p className="bio-text">{profileData.bio ? profileData.bio : "No bio provided."}</p>
        </div>
        <div className="contact-section">
          <h2 className="contact-title">Contact Information</h2>
          <p className="contact-discord">
            <FontAwesomeIcon icon={faDiscord} className="contact-icon" /> {user.username}
          </p>
          {userData && userData.robloxUserID && (
            <p className="contact-roblox">
              <FontAwesomeIcon icon={faGlobe} className="contact-icon" />
              Roblox Profile:{" "}
              <a href={userData.robloxUserID ? `https://www.roblox.com/users/${userData.robloxUserID && userData.robloxUserID}/profile` : ""} className="contact-roblox-link">
                roblox.com
              </a>
            </p>
          )}
        </div>
        {profileData && profileData.activities && (profileData.activities.edu || profileData.activities.project) && (
          <div className="recent-activities-section">
            <h2 className="activities-title">Recent Activities</h2>
            <ul className="activities-list">
              {/* <li className="activity-item">
        <FontAwesomeIcon icon={faBlog} className="activity-icon" />
        Posted a new blog article:{" "}
        <a href="#" className="activity-link">
          "Understanding JavaScript Closures"
        </a>
      </li> */}
              <li className="activity-item">
                <FontAwesomeIcon icon={faGraduationCap} className="activity-icon" />
                Recent Achievement: {profileData.activities.edu}
              </li>
              <li className="activity-item">
                <FontAwesomeIcon icon={faProjectDiagram} className="activity-icon" />
                Current Project: {profileData.activities.project}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
