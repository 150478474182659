import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faGlobe, faDownload } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CSS/Statistics.css";

const Statistics = () => {
  const [stats, setStats] = useState({
    guildsCount: "Loading...",
    userCount: "Loading...",
    commandCount: "Loading...",
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    fetch("/api/stats")
      .then((response) => response.json())
      .then((data) => {
        setStats(data);
      })
      .catch((error) => {
        setStats({
          guildsCount: "Unable to load API.",
          userCount: "Unable to load API.",
          commandCount: "Unable to load API.",
        });
      });
  }, []);

  return (
    <section id="statistics" data-aos="fade-up">
      <div className="container">
        <h2 data-aos="fade-up">Our Statistics</h2>
        <p className="statistic-description" data-aos="fade-up">
          We may look small, but on the inside we're not. With thousands of lines of front-end and back-end code, we provide the best experience for you.
        </p>
        <div className="statistics-container" data-aos="fade-up">
          <Statistic icon={<FontAwesomeIcon icon={faUsers} />} value={stats.userCount} label="Users" />
          <Statistic icon={<FontAwesomeIcon icon={faGlobe} />} value={stats.guildsCount} label="Servers" />
          <Statistic icon={<FontAwesomeIcon icon={faDownload} />} value={stats.commandCount} label="Commands" />
        </div>
      </div>
    </section>
  );
};

const Statistic = ({ icon, value, label }) => {
  return (
    <div className="statistic" data-aos="fade-up">
      <i className="fas">{icon}</i>
      <span className="statistic-value">{value}+</span>
      <span className="statistic-label">{label}</span>
    </div>
  );
};

export default Statistics;
