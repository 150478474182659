import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CSS/PremiumPerks.css";

const PremiumPerks = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section id="premium-perks" data-aos="fade-up">
      <div className="container">
        <h1 className="premium-perks-heading" data-aos="fade-right">
          Premium Perks
        </h1>
        <div className="perks-table">
          <div className="perk" data-aos="fade-up">
            <h3>Premium Commands</h3>
            <p>Gain access to all of our premium commands.</p>
          </div>
          <div className="perk" data-aos="fade-up">
            <h3>Premium Features</h3>
            <p>Use all of our premium features.</p>
          </div>
          <div className="perk" data-aos="fade-up">
            <h3>Premium Customization</h3>
            <p>Enjoy the extra customization features that allow you to better personialize Cyberblox.</p>
          </div>
          <div className="perk" data-aos="fade-up">
            <h3>Verify Your Whole Server</h3>
            <p>Verify your whole server at anytime with no limit.</p>
          </div>
          <div className="perk" data-aos="fade-up">
            <h3>Faster Stat Updates</h3>
            <p>Premium Server's Statistics update every two minutes compared to five minutes for basic servers.</p>
          </div>

          <div className="perk" data-aos="fade-up">
            <h3>More On The Way</h3>
            <p>These are not all the features and we are currently working on developing more!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PremiumPerks;
