import React from "react";
import "./CSS/TransactionResultCard.css";

const TransactionResultCard = ({ status, product, currentDate, sessionID, expiresDate }) => {
  const handleContinueClick = () => {
    window.location.href = "/";
  };

  return (
    <div id="card" aria-label="Cancel Card">
      <div className="transaction-info">
        <h3 aria-label="Transaction Title">{status}</h3>
        <p className="support-text">
          If you need any help, please{" "}
          <a href="/support" target="_blank" className="support-link" aria-label="Contact Support">
            contact support
          </a>
          .
        </p>
        <p>
          <b aria-label="Product">Product:</b> {product}
        </p>
        <p>
          <b aria-label="Date">Date:</b> {currentDate}
        </p>
        {sessionID && (
          <p>
            <b aria-label="Session ID">Session ID:</b> {sessionID}
          </p>
        )}
        {expiresDate && (
          <p>
            <b aria-label="Expires Date">Expires Date:</b> {expiresDate}
          </p>
        )}
      </div>
      <button className="continue-btn" onClick={handleContinueClick} aria-label="Continue">
        Continue
      </button>
    </div>
  );
};

export default TransactionResultCard;
