import React from "react";
import { Navbar, PremiumPerks, CTA, Footer } from "../Components";

const PremiumPerksPage = () => {
  return (
    <div>
      <Navbar />
      <PremiumPerks />
      <CTA />
      <Footer />
    </div>
  );
};

export default PremiumPerksPage;
