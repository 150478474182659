import { ServerNotFound } from "../DashboardComponents";
import { Navbar, Footer } from "../Components";
import withAuthentication from "../withAuthentication";

const ServerNotFoundPage = () => {
  return (
    <div>
      <Navbar />
      <ServerNotFound />
      <Footer />
    </div>
  );
};

export default withAuthentication(ServerNotFoundPage);
// export default ServerNotFoundPage;
