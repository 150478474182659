import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CSS/VerificationCompleteCard.css";

import { BWLogo } from "../Assets";

const VerificationCompleteCard = () => {
  const [user, setUser] = useState("N/A");
  const [userImg, setUserImg] = useState(BWLogo);

  const navigate = useNavigate();

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const rbxUser = getCookie("rbx_user");
    const rbxImgEncoded = getCookie("rbx_img");

    if (rbxUser) {
      setUser(rbxUser);
    }

    if (rbxImgEncoded) {
      setUserImg(decodeURIComponent(rbxImgEncoded));
    }
  }, []);

  const handleNavigation = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <div className="verification-complete-card-container">
      <div className="verification-complete-card">
        <img src={userImg} alt="Verification Complete" />
        <h2>Welcome, {user}!</h2>
        <p>
          You have been verified as <b>{user}</b>. To change the account you are verified with, run the verification command again.
        </p>
        <a href="/" onClick={(event) => handleNavigation(event, "/")} title="Return Home">
          Return Home
        </a>
      </div>
    </div>
  );
};

export default VerificationCompleteCard;
