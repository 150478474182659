import React from "react";
import "./CSS/Legal.css";

const TermsOfService = () => {
  return (
    <section id="legal">
      <div className="container-legal legal">
        <h1 className="legal">Cyberblox Terms Of Service</h1>

        <p className="legal">Last Updated: [1/1/2024]</p>

        <p className="legal">Welcome to Cyberblox! These Terms of Service govern your use of our services, Cyberblox and cyberblox.org. Please read these Terms carefully before using our Service.</p>

        <p className="legal">By accessing or using Cyberblox, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using Cyberblox.</p>

        <h2 className="legal">1. Acceptance of Terms</h2>
        <p className="legal">By using Cyberblox, you understand that Cyberblox and its developers cannot be held accountable for any actions performed by Cyberblox. You are responsible for anything that happens in your guild and to your members. We also reserve the right to revoke your use of Cyberblox at any point in time. Therefore, Cyberblox reserves the right to deny service and terminate a membership/subscription without a refund.</p>

        <h2 className="legal">2. User Conduct</h2>
        <p className="legal">You agree to use Cyberblox in compliance with applicable laws and regulations and refrain from engaging in any prohibited activities. Prohibited activities include, but are not limited to:</p>
        <ul className="legal">
          <li>Violating any applicable laws or regulations</li>
          <li>Infringing upon the intellectual property rights of others</li>
          <li>Uploading or transmitting harmful software, viruses, or any malicious code</li>
          <li>Interfering with or disrupting Cyberblox or its servers</li>
          <li>Impersonating another person or entity</li>
          <li>Collecting or storing personal information of other users without their consent</li>
        </ul>

        <h2 className="legal">3. Intellectual Property Rights</h2>
        <p className="legal">Cyberblox and its contents, including but not limited to text, graphics, logos, images, software, and trademarks, are the intellectual property of Michael_Master13 and are protected by applicable intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to use Cyberblox solely for personal or internal business purposes.</p>

        <h2 className="legal">4. Privacy and Data Protection</h2>
        <p className="legal">We collect and process user data in accordance with our Privacy Policy, which is incorporated by reference into these Terms. By using Cyberblox, you consent to the collection, use, and sharing of your information as described in our Privacy Policy.</p>

        <h2 className="legal">5. Termination</h2>
        <p className="legal">We reserve the right to suspend or terminate your access to Cyberblox without prior notice if we determine, in our sole discretion, that you have violated these Terms or engaged in any activities that may harm Cyberblox or other users. You may also terminate your account information at any time by contacting us.</p>

        <h2 className="legal">6. Limitation of Liability</h2>
        <p className="legal">To the maximum extent permitted by law, Cyberblox and its affiliates, owners, developers, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or business opportunities arising out of or in connection with Cyberblox.</p>

        <h2 className="legal">7. Modifications</h2>
        <p className="legal">We reserve the right to modify or update these Terms at any time. You will be notified of any changes via email or by a notice on Cyberblox's support server. Your continued use of Cyberblox after the effective date of the revised Terms constitutes your acceptance of the updated Terms.</p>

        <h2 className="legal">8. User Responsibilities</h2>
        <p className="legal">As a user of Cyberblox, you have the responsibilty to report any issues to our development team. Abuse of any bugs or bypasses may lead to termination.</p>

        <h2 className="legal">9. Billing</h2>
        <p className="legal">Purchasing anything from our website is non-refundable. If you attempt to "dispute" your payment with your payment provider, you will be terminated from using Cyberblox's services. If you have any issues or you did not receive your subscription, please contact us via support.</p>
        <ul className="legal">
          <li>
            <b>All billing is managed by Stripe&reg;. Please read their terms of service regarding billing.</b>
          </li>
        </ul>

        <br />

        <h2 className="legal">Changes to these Terms Of Service</h2>
        <p className="legal">We may update these Terms Of Service from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with a revised "Last Updated" date. We encourage you to review these Terms Of Service periodically to stay informed about our Terms Of Service.</p>

        <br />
        <br />

        <h1 className="legal">Contact Us</h1>

        <p className="legal">
          If you have any questions or concerns regarding these Terms, please contact us at{" "}
          <a href="/support" title="Contact Support">
            cyberblox.org/support
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default TermsOfService;
