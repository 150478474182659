import React, { useEffect, useState } from "react";

const withAuthentication = (WrappedComponent) => {
  return (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
      fetch("/api/isauthenticated")
        .then((response) => response.json())
        .then((data) => setIsAuthenticated(data.isAuthenticated))
        .catch((error) => console.error("Error fetching authentication status:", error));
    }, []);

    if (isAuthenticated === null) {
      return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
      window.location.href = `/api/login?originalUrl=${window.location.href}`;
      return null; // Return null since the component will unmount after the redirect
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthentication;
