import { Navbar, TransactionResultCard, Footer } from "../Components";
import { useLocation } from "react-router-dom";

const CancelProductPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const productData = {
    status: "Transaction Canceled",
    product: queryParams.get("product") || "Default Product",
    currentTime: queryParams.get("currentTime") || "12:00:00",
    currentDate: queryParams.get("currentDate") || new Date().toString(),
  };

  return (
    <div>
      <Navbar />
      <TransactionResultCard {...productData} />
      <Footer />
    </div>
  );
};

export default CancelProductPage;
