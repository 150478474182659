import React from "react";
import "./CSS/Legal.css";

const CommunityGuidelines = () => {
  return (
    <section id="legal">
      <div className="container-legal legal">
        <h1 className="legal">Community Guidelines</h1>
        <b>
          <p className="legal">These guidelines are specifically applicable to servers associated with Cyberblox Services.</p>
        </b>
        <p className="legal">Last Updated: [5/20/2024]</p>

        <p className="legal">These Community Guidelines are expected to be followed at all times. Failure to do so will result in moderation.</p>

        <h2 className="legal">Swearing</h2>

        <p className="legal">Swearing is allowed but keep it to a minium. Swearing too much or swearing towards others may lead to a warning.</p>

        <h2 className="legal">Spamming</h2>

        <p className="legal">Spamming is not allowed, as it floods channels please keep messages to a reasonable size to avoid being punished.</p>

        <h2 className="legal">Bullying</h2>

        <p className="legal">Bullying or harassing anyone in this server is not tolerated.</p>

        <h2 className="legal">NSFW Content</h2>

        <p className="legal">No NSFW of any kind is allowed in this server under any circumstances, including but not limited to Text Channels, Voice Channels and Forums.</p>

        <h2 className="legal">Disrespect</h2>

        <p className="legal">Disrespect is not allowed, if you feel like you have been disrespected please report this to our staff team.</p>

        <h2 className="legal">Terms Of Service</h2>

        <ul className="legal">
          <li>
            You must follow the{" "}
            <a href="/terms-of-service" title="Cyberblox Terms of Service">
              Cyberblox Terms of Service
            </a>
          </li>
          <li>
            You must follow the{" "}
            <a href="https://discord.com/terms" title="Discord Terms of Service">
              Discord Terms of Service
            </a>
          </li>
        </ul>
        <br />
        <br />
        <h2 className="legal">Changes to these Guidelines</h2>

        <p className="legal">We may update these Guidelines from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with a revised "Last Updated" date. We encourage you to review these Guidelines periodically to stay informed about how we expect you to behave in our community.</p>

        <br />
        <br />

        <h1 className="legal">Contact Us</h1>

        <p className="legal">
          If you have any questions or concerns regarding these Guidelines, please contact us at{" "}
          <a href="/support" title="Contact Support">
            cyberblox.org/support
          </a>
          .
        </p>

        <br />

        <p className="legal">
          <b>Please follow our rules at all times. Our rules may update/change please check this webpage often so you are up to date with our rules and expectations.</b>
        </p>
      </div>
    </section>
  );
};

export default CommunityGuidelines;
