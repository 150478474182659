import { Navbar, CookiePolicy, Footer } from "../Components";

const CookiePolicyPage = () => {
  return (
    <div>
      <Navbar />
      <CookiePolicy />
      <Footer />
    </div>
  );
};

export default CookiePolicyPage;
