import { Navbar, CommunityGuidelines, Footer } from "../Components";

const GuidelinesPage = () => {
  return (
    <div>
      <Navbar />
      <CommunityGuidelines />
      <Footer />
    </div>
  );
};

export default GuidelinesPage;
