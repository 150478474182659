import { Navbar, UserProfile, Footer } from "../Components";

const UserProfilePage = () => {
  return (
    <div>
      <Navbar />
      <UserProfile />
      <Footer />
    </div>
  );
};

export default UserProfilePage;
