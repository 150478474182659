import { Navbar, PrivacyPolicy, Footer } from "../Components";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Navbar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
