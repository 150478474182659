import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import PremiumPage from "./Pages/PremiumPage";
import SupportPage from "./Pages/SupportPage";
import UserProfilePage from "./Pages/UserProfilePage";

// Legal
import TOSPage from "./Pages/TOSPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import CookiePolicyPage from "./Pages/CookiePolicyPage";
import AttributionsPage from "./Pages/AttributionsPage";
import GuidelinesPage from "./Pages/GuidelinesPage";

//UnListed
import PremiumPerksPage from "./Pages/PremiumPerksPage";
import CancelProductPage from "./Pages/CancelProductPage";
import NotFoundPage from "./Pages/NotFoundPage";
import VotePage from "./Pages/VotePage";
import DiscordToolsPage from "./Pages/DiscordToolsPage";
import VerificationCompletePage from "./Pages/VerificationCompletePage";

// import ServersPage from "./DashboardPages/ServersPage";
// import ServerConfigPage from "./DashboardPages/ServerConfigPage";
// import VerificationPage from "./DashboardPages/VerificationPage";
// import BindsPage from "./DashboardPages/BindsPage";
// import GroupConfigurationPage from "./DashboardPages/GroupConfigurationPage";
// import SettingsPage from "./DashboardPages/SettingsPage";
// import WelcomeLeavePage from "./DashboardPages/WelcomeLeavePage";
// import ReactionRolesPage from "./DashboardPages/ReactionRolesPage";
// import ConfigureLogsPage from "./DashboardPages/ConfigureLogsPage";

import ServerNotFoundPage from "./DashboardPages/ServerNotFoundPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/premium-perks" element={<PremiumPerksPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/users/:id/profile" element={<UserProfilePage />} />

        <Route path="/terms-of-service" element={<TOSPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/attributions" element={<AttributionsPage />} />
        <Route path="/guidelines" element={<GuidelinesPage />} />

        <Route path="/premium" element={<PremiumPage />} />
        <Route path="/cancel" element={<CancelProductPage />} />
        <Route path="/vote" element={<VotePage />} />
        <Route path="/discord-tools" element={<DiscordToolsPage />} />
        <Route path="/verification-complete" element={<VerificationCompletePage />} />

        {/* <Route path="/dashboard/servers" element={<ServersPage />} />
        <Route path="/dashboard/server/:id" element={<ServerConfigPage />} />
        <Route path="/dashboard/server/:id/verification" element={<VerificationPage />} />
        <Route path="/dashboard/server/:id/binds" element={<BindsPage />} />
        <Route path="/dashboard/server/:id/group-configuration" element={<GroupConfigurationPage />} />
        <Route path="/dashboard/server/:id/settings" element={<SettingsPage />} />
        <Route path="/dashboard/server/:id/welcome-leave" element={<WelcomeLeavePage />} />
        <Route path="/dashboard/server/:id/reaction-roles" element={<ReactionRolesPage />} />
        <Route path="/dashboard/server/:id/configure-logs" element={<ConfigureLogsPage />} /> */}

        <Route path="/dashboard/server/not-found" element={<ServerNotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
