import { Navbar, NotFound, Footer } from "../Components";

const NotFoundPage = () => {
  return (
    <div>
      <Navbar />
      <NotFound />
      <Footer />
    </div>
  );
};

export default NotFoundPage;
