import React from "react";
import "./CSS/Legal.css";

const CookiePolicy = () => {
  return (
    <section id="legal">
      <div className="container-legal legal">
        <h1 className="legal">Cookie Policy</h1>

        <p className="legal">Last Updated: [1/1/2024]</p>

        <p className="legal">This Cookie Policy explains how Cyberblox uses cookies and similar technologies on its website (cyberblox.org). By using the website, you consent to the use of cookies and the terms of this policy.</p>

        <h2 className="legal">What are Cookies?</h2>

        <p className="legal">Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work more efficiently and to provide a better user experience. Cookies enable websites to recognize your device and remember information about your visit, such as your preferences and settings.</p>

        <h2 className="legal">Types of Cookies We Use</h2>

        <br />

        <h3 className="legal">Essential Cookies</h3>

        <p className="legal">These cookies are necessary for the functioning of the website and cannot be switched off in our systems. They usually are set in response to actions made by you, such as setting your privacy preferences, logging in, or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the website may not function properly.</p>

        <h3 className="legal">Analytics Cookies</h3>

        <p className="legal">We use analytics cookies to collect information about how visitors use our website. This information is aggregated and anonymous, and it helps us understand how our website is performing and improve its functionality. These cookies do not identify you personally. Our analytics cookies are provided by third-party services, such as Google Analytics. You can opt-out of analytics cookies by adjusting your browser settings or using the opt-out mechanisms provided by the third-party service.</p>

        <h3 className="legal">Advertising Cookies</h3>

        <p className="legal">We may also use advertising cookies to deliver personalized advertisements that are relevant to your interests. These cookies may track your browsing activity across different websites and collect information to provide targeted advertising. Our advertising cookies are provided by third-party advertising networks. You can opt-out of targeted advertising by adjusting your browser settings or visiting the opt-out pages of the respective advertising networks.</p>

        <h2 className="legal">Managing Cookies</h2>

        <p className="legal">You can control and manage cookies in various ways. Most web browsers allow you to accept or reject cookies, delete cookies already stored on your device, or be notified when a cookie is sent. Please note that if you block or delete cookies, some features of the website may be affected, and you may have to manually adjust some preferences or settings each time you visit the website.</p>

        <p className="legal">For more information about how to manage cookies, you can refer to the instructions or Help pages of your browser.</p>

        <h2 className="legal">Changes to this Cookie Policy</h2>

        <p className="legal">We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with a revised "Last Updated" date. We encourage you to review this Cookie Policy periodically to stay informed about our use of cookies.</p>

        <br />
        <br />

        <h2 className="legal">Contact Us</h2>

        <p className="legal">
          If you have any questions or concerns regarding these Terms, please contact us at{" "}
          <a href="/support" title="Contact Support">
            cyberblox.org/support
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default CookiePolicy;
