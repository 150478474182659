import React, { useEffect } from "react";
import AOS from "aos";
import { BotListME, TopGG, discordbotlistCOM, discordsCOM, radarcordNET, voidbotsNET } from "../Assets";
import "aos/dist/aos.css";
import "./CSS/VoteCards.css";

const VoteCards = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div class="vote-page-container">
      <div class="vote-title">
        <h1 class="vote-title-text">Vote For Cyberblox!</h1>
      </div>
      <div class="vote-description">
        <p class="vote-description-text">Earn 1x vote credit per vote!</p>
      </div>

      <div class="vote-card-container">
        <div class="vote-card">
          <img src={TopGG} alt="Top.gg Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Top.gg</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Top.gg!
            </p>
            <a href="https://top.gg/bot/1100883987578228746/vote" title="Vote on Top.gg" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>

        <div class="vote-card">
          <img src={BotListME} alt="Botlist.me Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Botlist.me</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Botlist.me!
            </p>
            <a href="https://botlist.me/bots/1100883987578228746/vote" title="Vote on Botlist.me" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>

        <div class="vote-card">
          <img src={discordbotlistCOM} alt="discordbotlist.com Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Discordbotlist.com</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Discordbotlist.com!
            </p>
            <a href="https://discordbotlist.com/bots/cyberblox/upvote" title="Vote on Discordbotlist.com" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>

        <div class="vote-card">
          <img src={voidbotsNET} alt="Void Bots Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Voidbots.net</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Voidbots.net!
            </p>
            <a href="https://voidbots.net/bot/1100883987578228746/vote" title="Vote on Voidbots.net" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>

        <div class="vote-card">
          <img src={radarcordNET} alt="Radarcord.net Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Radarcord.net</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Radarcord.net!
            </p>
            <a href="https://radarcord.net/bot/1100883987578228746/vote" title="Vote on Radarcord.net" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>

        <div class="vote-card">
          <img src={discordsCOM} alt="Discords.com Logo" class="vote-card-image" />
          <div class="vote-card-content">
            <h2 class="vote-card-title">Discords.com</h2>
            <p class="vote-card-description">
              Vote for <b>Cyberblox</b> on Discords.com!
            </p>
            <a href="https://discords.com/bots/bot/1100883987578228746/vote" title="Vode on Discords.com" target="_blank" rel="noreferrer" class="vote-button">
              Vote Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoteCards;
