import { Navbar, PremiumTable, CTA, Footer } from "../Components";
import withAuthentication from "../withAuthentication";

const PremiumPage = () => {
  return (
    <div>
      <Navbar />
      <PremiumTable />
      <CTA />
      <Footer />
    </div>
  );
};

export default withAuthentication(PremiumPage);
// export default PremiumPage;
