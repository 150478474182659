import React from "react";
//Uses 404 CSS From Main Site

function ServerNotFound() {
  return (
    <div className="not-found">
      <div className="container">
        <div className="not-found-content">
          <h1 className="not-found-title">Server Not Found!</h1>
          <p className="not-found-text">Oops! The server you're looking for doesn't exist.</p>
          <p className="return-text">Click the button below to return back to the main site.</p>
          <a href="/" className="not-found-button">
            Return to Home Page
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServerNotFound;
